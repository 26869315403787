jQuery(document).ready(function () {
    initializeSlick();
    toggleFaq();
    navigator();
    initMap();
    blogCategoryFilter();
    productAccordion();
    removeBreadCrumbLine();
    initBrowserCheck();
    initReviewForm();
    initModal();
});

function initializeSlick() {
    jQuery('.slider-container').slick({
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 3000,
        arrows: false,
        dots: true
    });
}

function toggleFaq() {
    $(".js-faq-title").on("click", function (e) {
        e.preventDefault();
        var $this = $(this);
        if (!$this.hasClass("faq__active")) {
            $(".js-faq-content").slideUp(800);
            $(".js-faq-title").removeClass("faq__active");
            $('.js-faq-rotate').removeClass('faq__rotate');
        }
        $this.toggleClass("faq__active");
        $this.next().slideToggle();
        $('.js-faq-rotate', this).toggleClass('faq__rotate');
    });
}


const navigator = () => {
    $('.ham-icon, .close-menu').click(function () {
        $('.ham-icon, .close-menu').toggle();
        $('.mobile-fullscreen').toggleClass('active');
    });

    $(".cross-top").on("click", () => {
        $(".top-head-container").fadeOut('slow');
        $('.mobile-fullscreen').css("top", "114px");
        $('.xoo-wsc-basket').css("margin-top", "-2.5em");
    });
};


function initMap() {
    let script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBvRTGLkTtHKXHgy-OpL4PVQhe_Bf-3PhM";
    jQuery('#accept-dsgvo').on('click', function () {
        document.documentElement.firstChild.appendChild(script);
        jQuery('.hide-on-accept').css('display', 'none');
        jQuery('.map-widget').css('background-image', 'unset');
        let loc = "https://www.google.com/maps/embed/v1/place?q=Lange+Straße+25,+33803+Steinhagen,+Germany&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8";
        jQuery('#map-location').css({"display": "block", "width": "100%", "height": "400px"});
        document.getElementById('map-location').setAttribute('src', loc);
    });
}

//for--woocommerce

function blogCategoryFilter() {
    // When a category is selected from the filter
    $('#category-filter a').click(function () {
        var category = $(this).data('category');
        $.ajax({
            url: ajax.url,
            type: 'post',
            data: {
                action: 'filter_posts',
                category: category
            },
            success: function (response) {
                $('.blog-post-content').html(response);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log(errorThrown);
            }
        });
        return false;
    });
}

function productAccordion() {
    $('.product-accordion-header').click(function () {
        $(this).toggleClass('active');
        $(this).find('.toggle-icon').toggleClass('plus minus');
        $(this).next('.product-accordion-content').slideToggle();
    });
}

function removeBreadCrumbLine() {
    $('.woocommerce-breadcrumb').html(function (_, html) {
        return html.replace(/&nbsp;\/&nbsp;/g, '');
    });
}

function initBrowserCheck() {
    function isIE() {
        var ua = window.navigator.userAgent;
        /* MSIE used to detect old browsers and Trident used for newer ones */
        var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

        return is_ie;
    }

    /* Create an alert to show if the browser is IE or not */
    if (isIE()) {
        jQuery('.ie-check').show();
    }
}

function initReviewForm() {
    jQuery('#review-form').on('submit', function (e) {
        e.preventDefault();
        var form_data = jQuery(this).serialize();
        jQuery.ajax({
            url: ajax.url,
            method: 'POST',
            data: form_data,
            dataType: 'json',
            success: function (result) {
                if (result.status === 'success') {
                    jQuery('#review-rate').empty().html('<div class="entry-title entry-title--underline"><p class="thank-you-title">' + result.messages.title + '</p></div><p class="thank-you-message">' + result.messages.success + '</p>');
                } else if (result.status === 'invalid') {
                    var list_messages = '';
                    jQuery.each(result.messages.invalid, function (index, value) {
                        list_messages += '<lI>' + value + '</li>';
                    });
                    list_messages = '<ul>' + list_messages + '</ul>';
                    jQuery('#review-rate-messages').empty().html('<div class="invalid-message">' + list_messages + '</div>');
                } else if (result.status === 'error') {
                    jQuery('#review-rate-messages').empty().html('<div class="invalid-message">' + result.messages.error + '</div>');
                } else {
                }
            },
            error: function (error) {
                console.log(error)
            }
        });
    });

    jQuery('.review-rating').on('click', 'label', function () {
        jQuery('input[name=review_rate]').removeAttr("checked");
        jQuery('#' + jQuery(this).attr('for')).attr('checked', 'checked');
    });
    review_stars();
}

var review_stars = function () {
    jQuery('.star-rating-input label').on('mouseover', function () {
        for (i = 1; i <= 5; i++) {
            var star = jQuery('#star-' + i);
            if (i <= jQuery(this).data('star')) {
                star.removeClass("disabled");
                star.removeClass("active");
                star.addClass("active");
            } else {
                star.removeClass("active");
                star.removeClass("disabled");
                star.addClass("disabled");
            }
        }
    });

    jQuery('.star-rating-input').on('mouseout', function () {
        for (i = 1; i <= 5; i++) {
            var star = jQuery('#star-' + i);
            star.removeClass("disabled");
            star.removeClass("active");

        }
    });
};

// Init Modal
function initModal() {
    let button_2 = jQuery('.reviews-trigger');
    let button_3 = jQuery('.review_buttons .btn--trans-2');
    let modal_2 = jQuery('#reviews-modal');
    let close_2 = jQuery('#close-reviews-modal');
    let form_2 = jQuery('#reviews-modal .wpcf7-form');

    // Open Modal
    button_2.on('click', function () {
        modal_2.fadeIn();
    });

    // Open Modal
    button_3.on('click', function () {
        modal_2.fadeIn();
    });

    // Close Modal
    close_2.on('click', function () {
        modal_2.addClass('animate__animated animate__zoomOut');
        setTimeout(function () {
            modal_2.hide();
            modal_2.removeClass('animate__animated animate__zoomOut');
        }, 1000);
    });


    // Show Modal if errors exist or successfully submitted
    if (form_2.hasClass('invalid') || form_2.hasClass('unaccepted') || form_2.hasClass('sent')) {
        modal_2.fadeIn();
    }

    // Set the active tab to Bewertung
    $('.acc-tab li').click(function () {
        let tabId = $(this).attr('data-tab');
        $('.acc-tab li').removeClass('active');
        $(this).addClass('active');
        $('.tab-content .tab-pane').removeClass('active animate__animated animate__fadeIn');
        $('#' + tabId + '-content').addClass('active animate__animated animate__fadeIn');
    });
}
